.icon-is-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(min-width: 769px) {
    .field-is-centered{
        padding: 0 15vw;
    }
    .message-is-centered{
        margin: 0 15vw;
    }
}

@media(max-width: 768px) {
    .message-header {
        border-radius: 0;
    }
}

.flash {
    position: fixed;
    width: 100%;
    text-align: center;
    z-index: 1;
    bottom: 0;
}

.message {
    margin-bottom: 0.7rem;
}

.small-message-body {
    padding: 0.5rem 1rem;
}

.message-block {
    background-color: beige;
    border-radius: 3px;
    padding-top: 3px;
    padding-left: 6px;
    padding-bottom: 3px;
    padding-right: 6px;
    margin-bottom: 6px;
}
