@import "variables";
@import "~bulma/bulma.sass";
// TODO nur importieren was benötigt wird
@import "@fortawesome/fontawesome-free/css/all.css";

@import "top-bar";
@import "messages";

div.dom_wrapper {
  position: sticky;  /* Fix to the top */
  top: 0;
  padding: 5px;
  background: rgba(255, 255, 255, 1);  /* hide the scrolling table */
}

.modal {
    padding-left: 10px;
    padding-right: 10px;    
}
.modal-card-title {
    @extend .is-size-4;
    @extend .is-size-5-mobile;
}
.close-modal {
    @extend .is-medium;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.sticky-footer{
    flex: 1;
}

.footer-link {
    color: black;
    text-align: center;
}

.footer {
    background-color: #f8faf9;
    padding: 3.0rem 1.5rem;
}

.is-underlined {
    text-decoration: underline;
}

.is-text-color {
    color: $uws-color-text;
}

.is-uws-color, .is-NONE-color {
    color: $uws-color-green;
}

.is-warning-color, .is-WARNING-color {
    color: $color-warning;
}

.is-error-color, .is-ERROR-color {
    color: $color-error;
}

.is-disabled-color {
    color: $color-disabled;
}

.is-background {
    color: $uws-color-text;
    background-color: $uws-color-background;
}

.is-text-color {
    color: $uws-color-text;
}

.is-secondary-text-color {
    color: $uws-color-secondary-text;
}

.full-width {
    width: 100%;
}

.slogan {
    color: $uws-color-green;
    font-weight: bold;
    font-size: xx-large;
}

.wallpaper {
    width: 100%;
    height: 45vh;
    object-fit: cover;
}

.subform:not(:last-child) {
    margin-bottom: 12px;
}

.sectionlabel {
    font-size: 1.5rem;
}

.sublabel {
    font-weight: normal;
}

.legendHeader {
    font-weight: bold;
    margin-bottom: 0.1rem;
    color: $uws-color-header;
}

.asterisk {
    color: $uws-color-red;
}

.checkbox-container {
    display: flex;
    align-items: baseline;
    input {
        margin-right: 0.5em;
    }
    label {
        width: 90%;
    }
}

.button-bar {
    padding-top: 0;
    padding-bottom: 0;
}

.button.is-navigation {
    height: 1.75rem;
    padding-left: 0.7rem;
    padding-right: 0.5rem;
    transition: all .2s ease-in !important;
}

.button.is-primary:not(.is-outlined) {
    transition: all 0.3s ease-in;
}
.button.is-primary:not(.is-outlined):hover {
    background-color: transparent;
    border-color: $uws-color-white;
    color: $uws-color-white;
    box-shadow: 0 0.5em 1em -0.125em rgba($secondary, 0.5), 0 0px 0 2px rgba($secondary, 0.5);
}

.button.is-secondary.is-outlined {
    transition: all 0.3s ease-in;
}
.button.is-secondary.is-outlined:hover {
    border-color: $uws-color-green;
    background-color: $uws-color-very-light-green;
}
.button.is-secondary.is-outlined.is-navigation {
    border-color: $uws-color-green;
}

.buttons-with-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.buttons-vertical {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.button-with-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-header {
    margin-bottom: 0.5rem;
}

.card.equal-height {
    height: 100%;
    display:flex;
    flex-direction: column;
}
.card-footer.baseline {
    margin-top: auto;
}

.hero,.reduced-top-padding {
    padding-top: 2rem;
}

.hero-body,.reduced-top-padding {
    padding-top: 1rem;
}

.rows {
    display: flex;
    flex-direction: column;
}

.inline-icon {
    font-size: 20px !important;
    margin-left: 0;
}

.has-addons {
    align-items: center;
}

.has-width-of-content {
    width: max-content;
}

.tab-content {
    display: none;
    &.is-active {
        display: block;
    }
}

.line-break{
    word-break: break-all;
}

.space-after {
    margin-bottom: 1rem;
}
.space-after-xl {
    margin-bottom: 2rem !important;
}
.space-after-small {
    margin-bottom: 0.5rem;
}
.space-after-xsmall {
    margin-bottom: 0.25rem;
}
.space-before {
    margin-top: 1rem;
}
.space-before-xsmall {
    margin-top: 0.25rem;
}

.monospaced {
    font-family: monospace;
}

@media(max-width: 1023px) {
    .wallpaper {
        height: 30vh;
    }
    .is-fullwidth-touch {
        display: flex;
        width: 100%;
        margin-right: 0 !important;
    }
    .org-card-container {
        flex-direction: column;
    }
}

@media(max-width: 768px) {
    .section {
        padding: 1.5rem;
    }
    .button-bar {
        flex-direction: column;
        padding-bottom: 0 !important;
        padding-top: 0.5rem !important;
    }
    .slogan {
        font-weight: 500;
        font-size: x-large;
    }
    .hero-body {
        padding: 1.5rem;
    }
    .box {
        background-color: initial;
        border-radius: initial;
        box-shadow: initial;
        color: initial;
        display: initial;
        padding: initial;
    }
    .mobile-split {
        display: flex;
    }
}

@media(min-width: 769px) {
    .section {
        padding: 2rem;
    }
    .intro-text {
        font-size: 1.2em;
    }
    .text-is-center-tablet {
        text-align: center;
    }
}

@media(min-width: 1024px) {
    .text-is-center-desktop {
        text-align: center;
    }
}
