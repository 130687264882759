.top-bar {
    height: 100px;
}

.top-element {
    margin-top: 0;
}

.uwslogo {
    height: 38px;
    margin-left: 14px;
    margin-top: 6px;
}

.contact-item {
    margin-left: 4rem;
}

.contact-title {
    display: inline-flex;
}

.title-icon {
    background-color: $uws-color-very-light-green;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    display: flex;
    padding: 4px;
}

.title-text {
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
    color: $uws-color-green;
    margin-left: 0.5rem;
}

.contact-link {
    color: $uws-color-text;
    white-space: nowrap;
}

.usermenu-org {
    padding-right: 0.5rem;
    font-size: 0.75rem;
}

.usermenu-button {
    margin-right: 5px;
    .button {
        border: 0;
        height: auto;
    }
    .button:focus {
        box-shadow: none;
    }
    .dropdown-item:hover {
        background-color: rgba($primary, 0.15);
    }
    .fa-bars {
        color: #fff;
    }
    .fa-stack {
        border-radius: 50%;
        background: $uws-color-green;
        box-sizing: border-box;
        border: 2px solid transparent;
        font-size: 0.75rem;
        padding: 1em;
        transition: all 0.3s ease-in;
    }
    .fa-stack:hover {
        background: white;
        border-color: $uws-color-green;
    }
    .fa-stack:hover .fa-bars {
        color: $uws-color-green;
    }
}

@media(min-width: 401px) {
    .usermenu-org {
        font-size: 1rem;
    }
    .usermenu-button {
        margin-right: 16px;
        .fa-stack {
            font-size: 1.33rem;
        }
    }
    .uwslogo {
        height: 56px;
    }
}
@media(max-width: 768px) {
    .external-links {
        flex-direction: column;
    }
}
@media(min-width: 769px) {
    .uwslogo {
        margin-left: 24px;
    }
    .external-links {
        flex-direction: row;
        .control:not(:last-child) {
            .button {
                margin-right: -1px;
            }
        }
        .control:not(:first-child):not(:last-child) {
            .button {
                border-radius: 0;
            }
        }
        .control:first-child:not(:only-child) {
            .button {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }
        }
        .control:last-child:not(:only-child) {
            .button {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
}
@media(min-width: 1024px) {
    .dropdown-menu {
        padding-right: 1vw;
    }
}
