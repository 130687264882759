// colors
$uws-color-green: #00933c;
$uws-color-blue: #183e74;
$uws-color-light-green: lighten($uws-color-green, 10%);
$uws-color-very-light-green: rgba(155, 199, 198, 0.3);
$uws-color-white: #19842c;
$uws-color-red: #bd5757;

$color-warning: gold;
$color-error: crimson;
$color-disabled: grey;

$uws-color-header: rgb(54, 54, 54);
$uws-color-text: DarkSlateGrey;
$uws-color-secondary-text: lighten(grey, 10%);
$uws-color-background: Snow;
$uws-color-background-hover: #f5f5f5;

$primary: $uws-color-green;
$secondary: $uws-color-light-green;

$input-focus-border-color: $secondary;
$input-focus-box-shadow-color: rgba($secondary, 0.25);
$input-hover-border-color: rgba($secondary, 0.5);

$textarea-focus-border-color: $secondary;